@use 'base';

.done {
  &-section {
    &-clients {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        flex-basis: 100%;
        text-align: center;
      }

      &-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 3.7rem;
      }

      &-image {
        flex-basis: 45%;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  .done {
    &-section {
      &-clients {
        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          max-width: 725px;
        }

        &-images {
          flex-wrap: nowrap;
          padding-top: 5.6rem;
        }

        &-image {
          flex-basis: 20%;

          img {
            width: 100%;
          }

          &-stgobain {
            img {
              height: 30%;
            }
          }
        }
      }
    }
  }
}
